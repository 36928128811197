/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import VariantModal from "./modal/variantModal"
import CartModal from "./modal/cartModal"
import Shopify from "./renderless/Shopify"
import ProductDetail from "./renderless/ProductDetail"
import "../styles/index.scss"

import chain_logo from "../../static/logos/PIJ-logomark-fill-white.svg"
import cart_icon from "../../static/icons/cart-full.svg"
import cart_icon_empty from "../../static/icons/cart-empty.svg"

import { setMobile, setParisTheme } from "../state/global"
import { setOrderModalOpen } from "../state/cart"

const Layout = ({
  children,
  dispatch,
  productMetaData,
  orderDetails,
  parisTheme,
  mobile,
}) => {
  useEffect(() => {
    _onWindowResize()
    window.addEventListener("resize", _onWindowResize)
  })

  function _onWindowResize() {
    if (window.innerWidth < 768) {
      dispatch(setMobile(true))
    } else {
      dispatch(setMobile(false))
    }
  }
  let bag = (
    <img
      onClick={() => dispatch(setOrderModalOpen(true))}
      className="bag m-0"
      src={cart_icon_empty}
      alt="bag"
    />
  )

  if (orderDetails.length > 0) {
    bag = (
      <div className="bag">
        <img
          onClick={() => dispatch(setOrderModalOpen(true))}
          src={cart_icon}
          alt="bag"
          className="m-0"
        />
        <span className="bag-count">{orderDetails.length}</span>
      </div>
    )
  }

  let body = (
    <Container fluid className={"bg-" + (parisTheme ? "blue" : "alrightRed")}>
      <Row>
        <Col className="logo-col-container d-none d-md-block" md={{ size: 1 }}>
          <Link to="/" onClick={() => setParisTheme(false)}>
            <img className="logomark" src={chain_logo} alt="pij chain" />
          </Link>
        </Col>
        <Col md={{ size: 10 }}>{children}</Col>
        <Col className="cart-col-container d-none d-md-block" md={{ size: 1 }}>
          {bag}
        </Col>
      </Row>
    </Container>
  )

  if (mobile) {
    body = (
      <Container fluid className={"bg-" + (parisTheme ? "blue" : "alrightRed")}>
        <Row className="mobile-nav-row">
          <Col>
            <Link to="/">
              <div className="mobile-logo-container">
                <img
                  className="logomark-mobile"
                  src={chain_logo}
                  alt="pij chain"
                />
              </div>
            </Link>
          </Col>
          <Col className="cart-col-container">{bag}</Col>
        </Row>
        <Row>
          <Col>{children}</Col>
        </Row>
      </Container>
    )
  }

  return (
    <>
      <Shopify />
      <ProductDetail />
      <VariantModal />
      <CartModal />
      {body}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(
  state => ({
    productMetaData: state.products.productMetaData,
    parisTheme: state.sixFeet.parisTheme,
    orderDetails: state.cart.orderDetails,
    mobile: state.global.mobile,
  }),
  null
)(Layout)
