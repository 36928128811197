import React, { useState } from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import Draggable from "react-draggable"
import wordmark from "../../../static/logos/PIJ-WordMark.svg"
import exit from "../../../static/icons/exit.svg"

import {
  setOrderModalOpen,
  updateOrderDetails,
  updateVariantModal,
} from "../../state/cart"

const CartModal = ({
  orderModalOpen,
  variantModal,
  orderDetails,
  products,
  productMetaData,
  mobile,
  dispatch,
}) => {
  let { productId, productSize, productQuantity } = orderDetails
  if (!orderModalOpen) {
    return ""
  }

  let subtotal = 0
  let orderCode = ""

  let orders = (
    <Row className="d-flex justify-content-center align-items-center h-100">
      <Col className="d-flex flex-column justify-content-center align-items-center">
        <h2>Why is your cart empty</h2>
        <Link
          to="/shop"
          className="underline"
          onClick={() => {
            dispatch(setOrderModalOpen(false))
            dispatch(
              updateVariantModal({
                isOpen: false,
                productId: variantModal.productId,
                productSize: variantModal.size,
                productQuantity: variantModal.productQuantity,
                sizesUnavailable: variantModal.sizesUnavailable,
              })
            )
          }}
        >
          start shopping
        </Link>
      </Col>
    </Row>
  )

  if (orderDetails.length > 0) {
    orders = orderDetails.map((order, idx) => {
      let productData = productMetaData[order.productId]
      let productCode = productData.variants.filter(i => {
        if (i.size === order.productSize) return i
      })
      orderCode = orderCode + productCode[0].variantId + ":1,"
      let price = 0
      if (productData) {
        price = parseInt(productData.price.split("$")[1])
      }
      subtotal = subtotal + price
      return (
        <Row key={idx} className="w-100 cart-item">
          <Col xs="3" md="3">
            <img
              src={productData.primaryImage}
              alt="cart"
              className="cart-img mb-0"
            />
          </Col>
          <Col xs="7" md="7" className="detail">
            <h2>{productData.title}</h2>
            <p>Size: {order.productSize}</p>
            <p
              onClick={() =>
                dispatch(
                  updateOrderDetails({
                    action: "remove",
                    index: idx,
                  })
                )
              }
              className="remove d-none d-md-block"
            >
              Remove
            </p>
          </Col>
          <Col xs="2" md="2" className="quantity-price">
            <h2>{productData.price}</h2>
            <p
              onClick={() =>
                dispatch(
                  updateOrderDetails({
                    action: "remove",
                    index: idx,
                  })
                )
              }
              className="remove d-block d-md-none"
            >
              Remove
            </p>
          </Col>
        </Row>
      )
    })
  }

  return (
    <Draggable disabled={mobile ? true : false}>
      <Container fluid className="cart-modal-container">
        <Row className="nav-row w-100">
          <Col
            xs="10"
            md="10"
            className="d-flex justify-content-start align-items-center"
          >
            <img src={wordmark} alt="wordmark" className="modal-logo m-0" />
          </Col>
          <Col
            onClick={() => {
              dispatch(setOrderModalOpen(false))
            }}
            xs="2"
            md="2"
            className="d-flex justify-content-end align-items-center"
          >
            <img src={exit} alt="exit" className="exit-modal m-0" />
          </Col>
        </Row>
        <Row className="cart-content-row">
          <Col>{orders}</Col>
        </Row>

        <Row className="subtotal-row">
          <Col className="d-flex align-items-center justify-content-start">
            <h2 className="mb-0">Subtotal:</h2>
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            <h2 className="mb-0">${subtotal}</h2>
          </Col>
        </Row>
        <Row className="checkout-row">
          {orderDetails.length > 0 ? (
            <a
              href={"https://checkout.parisinjuly.com/cart/" + orderCode}
              className="w-100 h-100"
            >
              <button>Checkout</button>
            </a>
          ) : (
            <button>Checkout</button>
          )}
        </Row>
      </Container>
    </Draggable>
  )
}

export default connect(
  state => ({
    orderModalOpen: state.cart.orderModalOpen,
    orderDetails: state.cart.orderDetails,
    variantModal: state.cart.variantModal,
    products: state.products.products,
    productMetaData: state.products.productMetaData,
    mobile: state.global.mobile,
  }),
  null
)(CartModal)
