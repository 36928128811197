import React from "react"
import { connect } from "react-redux"
import { Container, Row, Col } from "reactstrap"
import Draggable from "react-draggable"
import wordmark from "../../../static/logos/PIJ-WordMark.svg"
import exit from "../../../static/icons/exit.svg"
import Image from "../image"

import {
  updateVariantModal,
  setOrderModalOpen,
  updateOrderDetails,
} from "../../state/cart"

const VariantModal = ({
  variantModal,
  products,
  productMetaData,
  mobile,
  dispatch,
}) => {
  let {
    isOpen,
    productId,
    productSize,
    productQuantity,
    sizesUnavailable,
  } = variantModal
  if (!isOpen) {
    return ""
  }

  if (!productQuantity) {
    productQuantity = 1
  }

  let sizeArr = ["S", "M", "L", "XL"]

  let sizeOptions = sizeArr.map((size, idx) => {
    let soldout = false
    sizesUnavailable.forEach(i => {
      if (i === size) {
        soldout = true
      }
    })
    if (soldout) {
      return (
        <Col
          key={idx}
          xs="2"
          md="2"
          className={
            "soldout variant-option " +
            (sizeArr.length === idx + 1 ? "" : "divider ") +
            (productSize === size ? "selected" : "")
          }
        >
          <h2>{size}</h2>
        </Col>
      )
    } else {
      return (
        <Col
          key={idx}
          xs="2"
          md="2"
          className={
            "variant-option " +
            (sizeArr.length === idx + 1 ? "" : "divider ") +
            (productSize === size ? "selected" : "")
          }
          onClick={() =>
            dispatch(
              updateVariantModal({
                isOpen: isOpen,
                productId: productId,
                productSize: size,
                productQuantity: productQuantity,
                sizesUnavailable: sizesUnavailable,
              })
            )
          }
        >
          <h2>{size}</h2>
        </Col>
      )
    }
  })

  let productData = productMetaData[productId]

  return (
    <Draggable disabled={mobile ? true : false}>
      <Container fluid className="variant-modal-container">
        <Row className="nav-row w-100">
          <Col
            xs="6"
            md="10"
            className="d-flex justify-content-start align-items-center"
          >
            <img src={wordmark} alt="wordmark" className="modal-logo m-0" />
          </Col>
          <Col
            onClick={() => dispatch(updateVariantModal({ isOpen: false }))}
            xs="6"
            md="2"
            className="d-flex justify-content-end align-items-center"
          >
            <img src={exit} alt="exit" className="exit-modal m-0" />
          </Col>
        </Row>
        <Row className="w-100 product-info-row">
          <div className="modal-image-container">
            <div className="primary-container">
              <img
                src={productData.primaryImage}
                alt="primary"
                className="primary-img"
              />
            </div>
            <div className="secondary-container">
              <img
                src={productData.secondaryImage}
                alt="secondary"
                className="secondary-img"
              />
            </div>
          </div>
          <div className="detail-container">
            <div className="price-title">
              <h2>{productData.title}</h2>
              <h2>{productData.price}</h2>
            </div>
            <div className="description">
              <p>{productData.description}</p>
            </div>
          </div>
        </Row>
        <Row className="variant-option-row">
          <Col
            xs="4"
            md="4"
            className="variant-option divider disabled text-center"
          >
            <h2>select size</h2>
          </Col>
          {sizeOptions}
        </Row>
        <Row className={"add-to-cart-row " + (productSize ? "" : "disabled")}>
          <button
            onClick={() => {
              if (productSize) {
                dispatch(setOrderModalOpen(true))
                dispatch(
                  updateOrderDetails({
                    productId: productId,
                    productSize: productSize,
                    productQuantity: productQuantity,
                    action: "add",
                  })
                )
              } else {
                alert("You forgot to select a size dummy!")
              }
            }}
          >
            Add To Cart
          </button>
        </Row>
      </Container>
    </Draggable>
  )
}

export default connect(
  state => ({
    variantModal: state.cart.variantModal,
    products: state.products.products,
    productMetaData: state.products.productMetaData,
    mobile: state.global.mobile,
  }),
  null
)(VariantModal)
