import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { connect } from "react-redux"

import { setProducts } from "../../state/products"

const Shopify = ({ dispatch }) => {
  const data = useStaticQuery(graphql`
    {
      allShopifyProduct {
        nodes {
          availableForSale
          description
          handle
          title
          variants {
            price
            sku
            availableForSale
          }
        }
      }
    }
  `)
  let index = Object.keys(data)[0]
  let products = data[index].nodes
  dispatch(setProducts(products))
  return ""
}

export default connect(state => ({}), null)(Shopify)
