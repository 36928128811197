import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { connect } from "react-redux"

import { setProductMetaData } from "../../state/products"

const ProductDetail = ({ dispatch, productMetaData }) => {
  const data = useStaticQuery(graphql`
    query {
      allProductsJson {
        edges {
          node {
            title
            description
            price
            productHandle
            primaryImage
            secondaryImage
            cartImage
          }
        }
      }
    }
  `)
  let index = Object.keys(data)[0]
  let products = data[index].edges
  let fullData = productMetaData
  products.forEach(i => {
    let item = i.node
    if (fullData[item.productHandle]) {
      fullData[item.productHandle].title = item.title
      fullData[item.productHandle].description = item.description
      fullData[item.productHandle].price = item.price
      fullData[item.productHandle].primaryImage = item.primaryImage
      fullData[item.productHandle].secondaryImage = item.secondaryImage
      fullData[item.productHandle].cartImage = item.cartImage
    }
  })
  dispatch(setProductMetaData(fullData))
  return ""
}

export default connect(
  state => ({
    productMetaData: state.products.productMetaData,
  }),
  null
)(ProductDetail)
